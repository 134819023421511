import dynamic from 'next/dynamic';
import type { CSSProperties } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { Ratio } from '@news/gql';
import type { ArticleBody, ArticleMeta, Node, NodeData } from '@news/lib';
import { NodeType, getImageFromFeatured, isNotNullish } from '@news/lib';
import { appendUtmTags, isPartner } from '@news/tracking';

import { Byline } from 'components/Byline';
import { FullscreenImageModal } from 'components/FullscreenImageModal';
import { LazyImage } from 'components/LazyImage';
import { SportStandingTable } from 'components/sport-standing-table';
import { useSize } from 'hooks/useSize';
import { useWindowSize } from 'hooks/useWindowSize';
import { trimUrl } from 'lib/helpers';
import type { NodeMap, NodeProps } from 'lib/richText';
import { BODY_WIDTH_MOBILE, TABLET } from 'styles/theme';
import { Slider } from 'views/feed/components/Slider';
import { EditorialBanner } from 'views/feed/components/editorial-banner';
import { FactBox } from 'views/feed/components/fact-box';
import { VideoPlayer } from 'views/feed/components/video-player/VideoPlayer';
import { PREVENT_COLLAPSE_CLASS } from 'views/feed/helpers';

import { toBrTags } from '../toBrTags';
import {
  EmbeddedVideoWrapper,
  Figcaption,
  Figure,
  Iframe,
  Paragraph,
  PollIframe,
  VideoDescription,
  VideoDescriptionHeadline,
  VideoDescriptionText,
} from './styles';

const ArticleAdPlacement = dynamic(
  () => import('components/ad-placements/ArticleAdPlacement').then((module) => module.ArticleAdPlacement),
  {
    ssr: false,
  }
);

const ImageComponent = ({
  image,
  style,
}: {
  image: Exclude<ArticleBody<'ImageData'>['image'], null>;
  style?: CSSProperties;
}) => {
  const fullscreenImageModalRef = useRef<HTMLDialogElement>(null);

  const openFullscreenImageModal = () => {
    const dialogElement = fullscreenImageModalRef.current;
    if (dialogElement) {
      (document.querySelector('body') as HTMLBodyElement).style.overflow = 'hidden';
      dialogElement.showModal();
    }
  };

  const imageComponent = image?.url ? (
    <div role="button" tabIndex={0} onClick={openFullscreenImageModal}>
      <LazyImage src={image.url} alt="" ratio={image.ratio} />
    </div>
  ) : null;

  return image?.url ? (
    <>
      <FullscreenImageModal fullscreenImageModalRef={fullscreenImageModalRef} src={image.url} caption={image.caption} />
      <Figure
        itemScope
        itemType="https://schema.org/ImageObject"
        style={{ ...style, position: 'relative' }}
        className={PREVENT_COLLAPSE_CLASS}
      >
        {image?.linkUrl ? <a href={image?.linkUrl}>{imageComponent}</a> : imageComponent}
        {image.caption && <Figcaption>{image.caption}</Figcaption>}
      </Figure>
      {image.byline && <Byline text={image.byline} />}
    </>
  ) : null;
};

type TVideoDescription = Pick<ArticleBody<'ExtendedVideoAssetData'>, 'headline' | 'isQuote' | 'text'>;

type EmbeddedVideoPlayerProps = {
  data: ArticleBody<'VideoPlayerData'> | ArticleBody<'ExtendedVideoAssetData'>;
  videoDescription?: TVideoDescription;
};

const EmbeddedVideoPlayer = ({ data, videoDescription }: EmbeddedVideoPlayerProps) => {
  const videoPlayerIdRef = useRef(crypto.randomUUID());

  const caption = data.__typename === 'VideoPlayerData' ? data.caption || undefined : undefined;
  const thumbnail = data.__typename === 'VideoPlayerData' ? getImageFromFeatured(data) : undefined;

  return data?.asset ? (
    <Figure className={PREVENT_COLLAPSE_CLASS}>
      <VideoPlayer
        variant={'video-asset'}
        videoAsset={data.asset}
        videoPlayerId={videoPlayerIdRef.current}
        captionOverride={caption}
        thumbnailOverride={thumbnail}
      />

      {videoDescription && (
        <VideoDescription as="figcaption">
          {videoDescription.headline && (
            <VideoDescriptionHeadline variant="body1-strong" as="p">
              {videoDescription.headline}
            </VideoDescriptionHeadline>
          )}
          <VideoDescriptionText variant="title2" as={videoDescription.isQuote ? 'blockquote' : 'p'}>
            {videoDescription.text}
          </VideoDescriptionText>
        </VideoDescription>
      )}
    </Figure>
  ) : null;
};

type ExtendedVideoAssetProps = {
  data: ArticleBody<'ExtendedVideoAssetData'>;
};

const ExtendedVideoAsset = ({ data }: ExtendedVideoAssetProps) => {
  return (
    <EmbeddedVideoPlayer
      data={data}
      videoDescription={{
        headline: data.headline,
        isQuote: data.isQuote,
        text: data.text,
      }}
    />
  );
};

const EmbeddedSliderWrapper = styled('div')`
  margin: 12px 0;
  width: ${BODY_WIDTH_MOBILE};
`;

const EmbeddedSlider = ({ data }: { data: ArticleBody<'ImageSliderData'> }) => {
  const { width: windowWidth } = useWindowSize();
  const sizeObject = useSize();
  const ref = sizeObject.ref;
  const width = Math.floor(sizeObject.width); //This has to be an integer in order for the calculations in the Slider component to work as intended
  const images = (data?.images ?? []).filter(isNotNullish);

  let gutter = 8;
  const itemWidth = width;
  const scrollWidth = itemWidth * images.length;
  let showArrows = false;
  let showDots = false;
  if (windowWidth && windowWidth >= TABLET) {
    gutter = 20;
    showArrows = true;
    showDots = true;
  }
  const height = itemWidth / (16 / 9);

  return (
    <EmbeddedSliderWrapper ref={ref}>
      {width > 0 && (
        <Slider
          className={PREVENT_COLLAPSE_CLASS}
          width={width}
          itemWidth={itemWidth + gutter}
          scrollWidth={scrollWidth}
          showArrows={showArrows}
          showDots={showDots}
          verticalCenter={height / 2}
        >
          {images.map((image, index) => (
            <ImageComponent
              key={image.id}
              style={{
                marginTop: 0,
                marginBottom: 0,
                marginRight: index === images.length - 1 ? 0 : `${gutter}px`,
                width: `${itemWidth}px`,
              }}
              image={{
                ...image,
                //The image slider itself is always 16x9. Images with another aspect ratio will be "contained"
                ratio: Ratio.Ratio_16x9,
              }}
            />
          ))}
        </Slider>
      )}
    </EmbeddedSliderWrapper>
  );
};

const EmbeddedYouTubeVideo = ({ data }: { data: ArticleBody<'YouTubeData'> }) => {
  const { videoId, playlistId, startPositionInSeconds } = data;
  if (!videoId && !playlistId) {
    return null;
  }

  const embedUrl = `https://www.youtube.com/embed/${
    playlistId && !videoId ? 'videoseries' : videoId
  }?modestbranding=1&rel=0${startPositionInSeconds ? `&start=${startPositionInSeconds}` : ''}
    ${playlistId ? `&list=${playlistId}` : ''}`;

  return (
    <EmbeddedVideoWrapper>
      <Iframe
        src={embedUrl}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </EmbeddedVideoWrapper>
  );
};

const EmbeddedPoll = ({ data }: { data: ArticleBody<'PollData'> }) => {
  return <PollIframe src={data.directLink} />;
};

const EmbeddedLink = ({ data, children }: React.PropsWithChildren<{ data: ArticleBody<'HyperLinkData'> }>) => {
  const url: string | null = data?.url ? trimUrl(data.url) : null;

  if (!url) {
    return <>{children}</>;
  }

  if (url.includes('https://www.tv4.se')) {
    return (
      <a href={url} className={PREVENT_COLLAPSE_CLASS} target="_top">
        {children}
      </a>
    );
  }
  if (isPartner(url)) {
    return (
      <a href={appendUtmTags(url, 'Link')} className={PREVENT_COLLAPSE_CLASS} target="_blank">
        {children}
      </a>
    );
  }

  return (
    <a href={url} className={PREVENT_COLLAPSE_CLASS} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const BlockQuote = styled.blockquote`
  margin: 12px 0;

  /** A block quote will always consist of paragraphs, prepend a dash before these paragraphs */
  & > p::before {
    content: '\2013\00A0';
  }
`;

const Table = styled('table')`
  margin-bottom: 1.5em;
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden;
  box-shadow: ${colors.gray.dark} 0 0 0 1px;
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
`;

const TableHeaderCell = styled('th')`
  background-clip: padding-box;
  background-color: ${colors.gray.fade};
  border: 1px solid ${colors.gray.dark};
  border-collapse: collapse;
  padding: 5px 6px;
  font-weight: 400;
  text-align: left;
  min-width: 48px;
  position: relative;
`;

const TableDataCell = styled('td')`
  border: 1px solid ${colors.gray.light};
  border-collapse: collapse;
  padding: 5px 6px;
  min-width: 48px;
  position: relative;
  vertical-align: top;
`;

const defaultNodeMap: NodeMap = {
  [NodeType.Ad]: ({ articleMeta }) => <ArticleAdPlacement keywords={articleMeta?.adKeywords} />,
  [NodeType.Document]: ({ children }) => <>{children}</>,
  [NodeType.Paragraph]: ({ children }) => <Paragraph variant="body1">{children}</Paragraph>,
  [NodeType.Text]: ({ children }) => toBrTags(children),
  [NodeType.Bold]: ({ children }) => <strong>{toBrTags(children)}</strong>,
  [NodeType.Italic]: ({ children }) => <em>{toBrTags(children)}</em>,
  [NodeType.Image]: ({ data }) => (data?.image ? <ImageComponent image={data.image} /> : null),
  [NodeType.UnorderedList]: ({ children }) => <ul>{children}</ul>,
  [NodeType.OrderedList]: ({ children }) => <ol>{children}</ol>,
  [NodeType.ListItem]: ({ children }) => <li>{children}</li>,
  [NodeType.Heading1]: ({ children }) => <h1>{children}</h1>,
  [NodeType.Heading2]: ({ children }) => <h2>{children}</h2>,
  [NodeType.Heading3]: ({ children }) => <h3>{children}</h3>,
  [NodeType.Heading4]: ({ children }) => <h4>{children}</h4>,
  [NodeType.Heading5]: ({ children }) => <h5>{children}</h5>,
  [NodeType.Heading6]: ({ children }) => <h6>{children}</h6>,
  [NodeType.HyperLink]: EmbeddedLink,
  [NodeType.HR]: () => <hr />,
  [NodeType.Blockquote]: ({ children }) => <BlockQuote>{children}</BlockQuote>,
  [NodeType.Underline]: ({ children }) => <u>{children}</u>,
  [NodeType.Code]: ({ children }) => <code>{children}</code>,
  [NodeType.VideoPlayer]: ({ data }) => <EmbeddedVideoPlayer data={data} />,
  [NodeType.ExtendedVideoAsset]: ({ data }) => <ExtendedVideoAsset data={data} />,
  [NodeType.ImageSlider]: ({ data }) => <EmbeddedSlider data={data} />,
  [NodeType.YouTubePlayer]: ({ data }) => <EmbeddedYouTubeVideo data={data as ArticleBody<'YouTubeData'>} />,
  [NodeType.FactBox]: ({ data }) => <FactBox data={data} />,
  [NodeType.Poll]: ({ data }) => <EmbeddedPoll data={data} />,
  [NodeType.EditorialBanner]: ({ data, articleMeta }) => <EditorialBanner data={data} articleMeta={articleMeta} />,
  [NodeType.Table]: ({ children }) => (
    <Table>
      <tbody>{children}</tbody>
    </Table>
  ),
  [NodeType.TableHeaderCell]: (props) => <TableHeaderCell>{props.children}</TableHeaderCell>,
  [NodeType.TableRow]: (props) => <tr>{props.children}</tr>,
  [NodeType.TableCell]: (props) => <TableDataCell>{props.children}</TableDataCell>,
  [NodeType.SportStandingsTable]: (props) => (
    <SportStandingTable leagues={props.data.leagues} defaultLeague={props.data.defaultLeague} />
  ),
};

interface IRenderNode {
  node: Node;
  options: {
    data: NodeData;
    nodeMap?: Partial<NodeMap>;
    articleMeta?: ArticleMeta;
  };
  index?: number;
}

export const renderNode = ({ node, options, index = 0 }: IRenderNode): React.ReactNode => {
  const Component = (options.nodeMap?.[node.nodeType] ?? defaultNodeMap[node.nodeType]) as React.FunctionComponent<
    NodeProps<unknown>
  >;
  if (!Component) {
    console.warn('unsupported type', node.nodeType);
    return null;
  }

  const foundData = node.id ? options.data?.find((x) => x.id === node.id)?.data : undefined;

  return (
    <Component data={foundData} id={node.id ?? ''} key={index} articleMeta={options.articleMeta}>
      {node.content !== undefined
        ? node.content
        : (node?.children ?? []).map((n: Node, i) => renderNode({ node: n, options, index: i }))}
    </Component>
  );
};
